<!-- =========================================================================================
    File Name: Position.vue
    Description: Page to change the placement xyz position in space
========================================================================================== -->

<template>
  <div class="vx-row">
    <div class="vx-col w-full mb-2">
      <form>
        <div class="vx-row">
          <div class="vx-col w-2/12">
            <vs-button @click="BackToMySpace()" class="w-full mr-3 mb-2" type="border" color="primary">{{
              $t('EditPageLocationBackButton')
            }}</vs-button>
          </div>

          <div class="vx-col w-5/12">
            <vs-button @click="reset()" class="w-full mr-3 mb-2" type="border" color="danger">{{
              $t('ResetButton')
            }}</vs-button>
          </div>
          <div class="vx-col w-5/12">
            <vs-button @click="updateSpace()" class="w-full mr-3 mb-2" type="border">{{ $t('SaveButton') }}</vs-button>
          </div>
        </div>
      </form>
    </div>

    <vs-tabs position="left" class="tabs-shadow-none" id="profile-tabs">
      <vs-tab icon-pack="feather" icon="icon-user" label="Location">
        <div class="tab-general md:ml-4 md:mt-0 mt-4 ml-0">
          <location-settings v-bind:space="space" @updateSpace="onSpaceUpdated"></location-settings>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-lock" label="Duration">
        <div class="tab-change-pwd md:ml-4 md:mt-0 mt-4 ml-0">
          <duration-settings v-bind:space="space" @updateSpace="onSpaceUpdated"></duration-settings>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-info" label="Graphics">
        <div class="tab-info md:ml-4 md:mt-0 mt-4 ml-0">
          <graphics-settings v-bind:space="space" @updateSpace="onSpaceUpdated"></graphics-settings>
        </div>
      </vs-tab>
      <vs-tab icon-pack="feather" icon="icon-github" label="Thumbnail">
        <div class="tab-social-links md:ml-4 md:mt-0 mt-4 ml-0">
          <space-thumbnail-upload v-bind:space="space"></space-thumbnail-upload>
        </div>
      </vs-tab>
      <vs-tab v-show="plan == 'CAP' || plan == 'Global'" icon-pack="feather" icon="icon-link-2" label="Space Copy">
        <div class="tab-text md:ml-4 md:mt-0 mt-4 ml-0">
          <vx-card title="Copy the content of this space (*BETA feature)">
            <div class="vx-row">
              <div class="vx-col w-1/2">
                <span>Destination Space</span>
                <v-select
                  :clearable="false"
                  v-model="spacePidCopyDestination"
                  label="text"
                  class="w-full mb-2"
                  :options="spacePidNameOptions"
                />
              </div>

              <div class="vx-col w-1/2">
                <vs-button
                  icon-pack="feather"
                  icon="icon-copy"
                  @click="ConfirmCopyAndReplace(spacePidCopyDestination.value)"
                  class="mt-6 w-full"
                  type="border"
                  color="primary"
                  >Copy and Replace</vs-button
                >
              </div>
            </div>
          </vx-card>
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
// Import tools to crop the hologram
import '@/assets/js/utils/greenscreen-video-tools.js'
import * as Utils from '@/assets/js/utils/utils.js'
import * as DestroyUtils from '@/assets/js/utils/destroy-utils.js'
import * as HoverlayUtils from '@/assets/js/utils/hoverlay-utils.js'
import 'video.js/dist/video-js.css'
import { uuid } from 'vue-uuid'
import vSelect from 'vue-select'
import SpaceThumbnailUpload from './SpaceThumbnailUpload.vue'
import LocationSettings from './settings/LocationSettings.vue'
import DurationSettings from './settings/DurationSettings.vue'
import GraphicsSettings from './settings/GraphicsSettings.vue'

// Leaflet
import L from 'leaflet'

import 'leaflet.locatecontrol'
import 'leaflet.locatecontrol/dist/L.Control.Locate.min.css'

// Leaflet Pulse Plugin
import '@ansur/leaflet-pulse-icon/dist/L.Icon.Pulse.css'
import 'leaflet-pulse-icon'

export default {
  inject: ['$validator'],
  components: {
    LocationSettings,
    DurationSettings,
    GraphicsSettings,
    SpaceThumbnailUpload,
    'v-select': vSelect,
  },
  data() {
    return {
      space: {
        description: null,
        range: null,
        latitude: 0,
        longitude: 0,
      },
      plan: HoverlayUtils.getLayerPlanName(),
      layer_name: JSON.parse(localStorage.getItem('layerInfo')).name,
      isCompassOriented: false,
      heading: 0,
      newTipText: 'Create from scratch',
      tipValue: { text: 'Create from scratch', value: 'Create from scratch' },
      uniqueTipsOptions: [],
      // space copy and remplace
      spacePidNameOptions: [],
      spacePidCopyDestination: null,
      enable_shadows: true,
      enable_light_estimation: true,
      enable_real_time_reflection_probe: true,
      preposition_floor: false,
      enable_environment_occlusion: false,
      enable_human_occlusion: false,
      is_access_level_unlisted: false,
      is_additive: false,
      on_loaded: false,
      config: {
        //wrap: true, // set wrap to true only when using 'input-group'
        // altFormat: 'M j, Y',
        altInput: true,
        // dateFormat: 'm-d-Y',
        enableTime: true,
      },
      modifiedPlacements: [],
      pageRefreshHack: 0,
    }
  },
  watch: {
    isCompassOriented: function() {
      if (this.isCompassOriented == true) {
        console.log(this.space)
        if (this.space.settings == undefined) this.space.settings = {}
        this.space.settings['compass_oriented'] = true
      } else {
        this.space.settings['compass_oriented'] = false
      }
      this.$eventBus.$emit('spaceChanged', this.space)
    },
    heading: {
      deep: true,
      handler(newHeading) {
        if (!this.space.settings) this.space.settings = {}
        this.space.settings.heading = newHeading
      },
    },
    is_access_level_unlisted: {
      async handler(updatedValue) {
        if (updatedValue) this.space.access_level = 'unlisted'
        else {
          this.space.access_level = 'everyone'
          await this.$nextTick()
          this.initMap()
          this.showOtherSpaces()
          this.initMarker()
        }
      },
    },
    is_additive: {
      handler(updatedValue) {
        this.space.settings.is_additive = updatedValue
      },
    },
    enable_light_estimation: {
      handler(updatedValue) {
        if (updatedValue) this.space.settings.light_intensity = 1
        else this.space.settings.light_intensity = 0
      },
    },
    enable_shadows: {
      handler(updatedValue) {
        this.space.settings.enable_shadows = updatedValue
      },
    },
    preposition_floor: {
      handler(updatedValue) {
        this.space.settings.preposition_floor = updatedValue
      },
    },
    enable_environment_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_environment_occlusion = updatedValue
      },
    },
    enable_human_occlusion: {
      handler(updatedValue) {
        this.space.settings.enable_human_occlusion = updatedValue
      },
    },
    on_loaded: {
      handler(updatedValue) {
        if (updatedValue == 'wait') this.space.settings.LoadingScreen.on_loaded = 'wait'
        if (updatedValue == 'start_experience') this.space.settings.LoadingScreen.on_loaded = 'start_experience'
      },
    },
    range: {
      handler(updatedValue) {
        this.rangeIcon.setRadius(updatedValue)
        if (updatedValue == true) {
          this.$vs.loading.close()
        }
      },
    },
  },
  computed: {
    spaces() {
      if (this.$store.state.hoverlay) {
        console.log(this.$store.state.hoverlay.spaces)
        return this.$store.state.hoverlay.spaces
      } else return []
    },
    anchors() {
      if (this.$store.state.hoverlay) {
        return this.$store.state.hoverlay.anchors
      } else return []
    },
    tipCount() {
      if (this.space.settings && this.space.settings.LoadingScreen && this.space.settings.LoadingScreen.tips)
        return this.space.settings.LoadingScreen.tips.length
      else return 0
    },
    spacePidNameDictionnary() {
      var d = {}
      if (this.spaces)
        this.spaces.forEach(space => {
          d[space.pid] = space.name
        })
      return d
    },
  },
  mounted() {
    // this.initMap()
    // this.showOtherSpaces()
    // this.initMarker()
    this.computeUniqueTips()
  },
  created() {
    this.initData()
    // setup space settings
    if (!this.space.settings) this.space.settings = {}
    else {
      if (this.space.settings.compass_oriented == true) {
        this.isCompassOriented = true
      } else {
        this.isCompassOriented = false
      }
      if (this.space.settings.heading) this.heading = this.space.settings.heading
      if (this.space.settings.enable_environment_occlusion == true) this.enable_environment_occlusion = true
      if (this.space.settings.enable_human_occlusion == true) this.enable_human_occlusion = true
      if (this.space.settings.is_additive == true) this.is_additive = true
      if (this.space.settings.enable_shadows == false) this.enable_shadows = false
      if (this.space.settings.light_intensity == 0) this.enable_light_estimation = false
      if (this.space.settings.reflection_probe == "studio") this.enable_real_time_reflection_probe = false
      if (this.space.settings.preposition_floor == false) this.preposition_floor = false
      else this.preposition_floor = true
    }

    if (this.space.access_level == 'everyone') this.is_access_level_unlisted = false
    else this.is_access_level_unlisted = true

    for (const [key, value] of Object.entries(this.spacePidNameDictionnary)) {
      if (key != this.space.pid) this.spacePidNameOptions.push({ text: value, value: key })
    }

    // LoadingScreen
    console.log('this.space.settings.LoadingScreen')

    console.log(this.space.settings.LoadingScreen)
    if (this.space.settings.LoadingScreen == null)
      this.space.settings.LoadingScreen = { tips: [], on_loaded: 'start_experience' }
    if (this.space.settings.LoadingScreen.on_loaded) this.on_loaded = this.space.settings.LoadingScreen.on_loaded
    else this.on_loaded = 'start_experience'
    console.log(this.space.settings.LoadingScreen)
  },
  beforeDestroy() {},
  methods: {
    computeUniqueTips() {
      this.uniqueTipsOptions = []
      this.uniqueTipsOptions.push({ text: this.newTipText, value: this.newTipText })
      // console.log('computeUniqueTips')
      this.spaces.forEach(space => {
        if (this.space.pid != space.pid && space.settings && space.settings.LoadingScreen) {
          // console.log(space.settings.LoadingScreen.tips.length)
          //     console.log('x x x x x x x x ')
          //     console.log(this.space.settings)
          space.settings.LoadingScreen.tips.forEach(tip => {
            // console.log('tip')
            // console.log(tip.text)
            if (!this.uniqueTipsOptions.includes(tip.text))
              this.uniqueTipsOptions.push({ text: tip.text, value: tip.text })
          })
        }
      })
    },
    BackToMySpace() {
      this.$router.go(-1)
      // this.$router.push({
      //   path: `/space/${this.space.pid}`,
      // })
    },

    getAnchorName(anchor_pid) {
      return this.$store.state.hoverlay.anchors.filter(anchor => anchor.pid == anchor_pid)[0].name
    },
    confirmDeletePlacement(placementPid) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: this.$t('MySpaceDeletionTitle'),
        text: this.$t('MySpaceDeletionDescription'),
        acceptText: this.$t('Delete'),
        accept: this.deletePlacement,
        parameters: [placementPid],
      })
    },
    deletePlacement(placementPid) {
      console.log('deletePlacement' + placementPid)
      this.$store
        .dispatch('hoverlay/deletePlacement', [placementPid])
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            color: 'success',
            title: 'Success',
            text: 'the space has been deleted! ',
          })

          // Remove the placement from the list
          this.modifiedPlacements = this.modifiedPlacements.filter(placement => placement.pid != placementPid)
        })
        .catch(err => {
          this.$vs.loading.close()
          console.error(err)
        })
    },
    editPlacementAnchor(placementPid) {
      this.$router.push({
        path: `/edit-placement-anchor/${placementPid}`,
      })
    },
    editHobject(hobject_pid) {
      this.$router.push({
        path: `/edit-hobject/${hobject_pid}`,
      })
    },
    onQrCodeCopy() {
      this.$vs.notify({
        title: 'Success',
        text: this.$t('QrCodeCopy'),
        color: 'success',
        iconPack: 'feather',
        position: 'bottom-left',
        icon: 'icon-check-circle',
      })
    },
    create() {
      this.$router.push({
        path: `/create/${this.latitude}/${this.longitude}`,
      })
    },
    updateSpace() {
      // Clear thumbnail field if it's not a File
      if (this.space.thumbnail && this.space.thumbnail instanceof Blob === false) delete this.space.thumbnail
      this.$store
        .dispatch('hoverlay/updateSpace', this.space)
        .then(() => {
          this.$vs.loading.close()
          this.$vs.notify({
            text: 'Space updated',
            color: 'success',
            icon: 'check_box',
          })
          console.log('SUCCESS')
        })
        .catch(error => {
          console.log(error.message)
          this.$vs.loading.close()
          this.$vs.notify({
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
          })
        })
    },
    reset() {},
    initData() {
      var space_pid = this.$route.params.space_pid
      // Create a clone of the space
      this.space = Object.assign(
        {},
        this.spaces.find(space => space.pid == space_pid)
      )
      this.present_from = new Date(this.space.present_from)
      this.present_to = new Date(this.space.present_to)
    },
    getHobject(hobject_pid) {
      return JSON.parse(
        JSON.stringify(this.$store.state.hoverlay.hobjects.filter(hobject => hobject.pid == hobject_pid)[0])
      )
    },
    AddExistingTip(text) {
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen = {}
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen.tips = []
      this.space.settings.LoadingScreen.tips.push({ text: text, duration: 4 })
      this.computeUniqueTips()
      this.pageRefreshHack += 1
    },
    AddTip(tipValue) {
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen = {}
      if (!this.space.settings.LoadingScreen) this.space.settings.LoadingScreen.tips = []
      if (tipValue.text == this.newTipText) {
        this.space.settings.LoadingScreen.tips.push({ text: 'Customize me', duration: 4 })
      } else this.space.settings.LoadingScreen.tips.push({ text: tipValue.text, duration: 4 })

      this.computeUniqueTips()
      this.pageRefreshHack += 1
    },
    RemoveTip(tip) {
      const id = this.space.settings.LoadingScreen.tips.indexOf(tip) // 2
      this.space.settings.LoadingScreen.tips.splice(id, 1)
      this.pageRefreshHack += 1
    },
    ConfirmCopyAndReplace(spacePidCopyDestination) {
      this.$vs.dialog({
        type: 'confirm',
        color: 'danger',
        title: 'Potentionaly destructive operation',
        text: 'You are about to copy a space into another one, the destination space will be erased',
        acceptText: 'Copy and Replace',
        accept: this.CopyAndReplace,
        parameters: [spacePidCopyDestination],
      })
    },
    async CopyAndReplace(spacePidCopyDestination) {
      this.$vs.loading()
      try {
        await this.$store.dispatch('hoverlay/copySpace', {
          source: this.space.pid,
          destination: spacePidCopyDestination[0],
        })
        await this.$store.dispatch('hoverlay/getSpaceContent', {
          layer_name: this.layer_name,
          pid: spacePidCopyDestination,
        })
        this.$vs.notify({
          text: 'Space copy in progress...',
          color: 'success',
          icon: 'check_box',
        })
      } catch (error) {
        this.$vs.notify({
          title: 'Error',
          text: error.message,
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger',
        })
        console.log(error)
      }
      this.$vs.loading.close()
    },
    onSpaceUpdated(space) {
      this.space = space
      console.log(' on Space updated')
    },
  },
}
</script>

<style lang="scss">
.card-no-padding > *:first-child {
  padding: 0rem;
}
</style>
